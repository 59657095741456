import { useLazyQuery, useMutation } from '@apollo/client';
import { PageBuilder } from 'containers';
import { PageBuilderKey } from 'enums';
import i18n from 'i18n';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';
import { getTableColumns } from 'utils';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Course as CourseModel, GetFaqs } from 'types';
import { renderCell } from 'utils/cell-renderer';
import { coursesCellRenderMapper, coursesInitialColumns } from './mappers';
import GET_COURSES_GQL from 'api/queries/getCourses';

const Courses: FC = () => {
    const [courses, setCourses] = useState<PaginationOutput<CourseModel>>({
        count: 0,
        items: null,
    });
    const navigate = useNavigate();

    const [getCoursesQuery] = useLazyQuery(GET_COURSES_GQL, {
        onCompleted: (response) => {
            const {
                courses: { get },
            } = response;
            setCourses(get);
        },
    });

    const getCourses = async (model: PaginationInput) => {
        await getCoursesQuery({
            variables: { ...model },
        });
    };

    return (
        <PageBuilder<CourseModel>
            pageKey={PageBuilderKey.Courses}
            data={courses}
            getItems={(params) => getCourses(params as GetFaqs)}
            columns={getTableColumns<CourseModel>(coursesInitialColumns, coursesCellRenderMapper, renderCell, {
                ...i18n.getResourceBundle(i18n.language, 'pages/courses'),
                ...i18n.getResourceBundle(i18n.language, 'common/shared'),
            })}
            addItem={() => navigate('/course')}
            updateItem={(record: CourseModel) => {
                const { id } = record;
                navigate(`/courses/${id}`);
            }}
        />
    );
};

export default Courses;
