import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import ADD_COURSE_GQL from 'api/mutations/addCourse';
import UPDATE_COURSE_GQL from 'api/mutations/updateCourse';
import GET_COURSE_GQL from 'api/queries/getCourse';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Course as CourseModel } from 'types';

export const INITIAL_DATA: Omit<CourseModel, 'creationDate'> = {
    id: '',
    name: '',
};

const Course: FC = () => {
    const { t } = useTranslation(['pages/courses', 'common/shared']);
    const { setToaster } = useToaster();
    const navigate = useNavigate();

    const [course, setCourse] = useState<Omit<CourseModel, 'creationDate'>>(INITIAL_DATA);
    const [getCourseQuery, { loading: courseLoading }] = useLazyQuery(GET_COURSE_GQL, {
        onCompleted: (response) => {
            setCourse(response.courses.byId);
        },
    });

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getCourseQuery({ variables: { id } });
        }
    }, [id]);

    const [addCourseMutation, { loading: addMutationLoading }] = useMutation(ADD_COURSE_GQL);
    const [updateCourseMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_COURSE_GQL);

    const methods = useForm<CourseModel>();

    const onSubmit = async (model: FieldValues) => {
        try {
            await (id
                ? updateCourseMutation({
                      variables: {
                          model: {
                              ...model,
                              id: course?.id,
                          },
                      },
                  })
                : addCourseMutation({
                      variables: {
                          model,
                      },
                      onCompleted: (response) => {
                          const {
                              questions: {
                                  add: { id },
                              },
                          } = response;
                          navigate('/course/all');
                      },
                  }));
            setToaster({
                type: 'success',
                message: t('course-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return !course || addMutationLoading || updateMutationLoading || courseLoading ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('course')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<Omit<CourseModel, 'creationDate'>>
                    data={course}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.Course}
                    loading={addMutationLoading || updateMutationLoading || courseLoading}
                    cancel={() => navigate('/courses/all')}
                    isFormEmpty={!id}
                />
            </FormProvider>
        </>
    );
};

export default Course;
