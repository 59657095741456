export enum FilterType {
    SortByName = 'sortByName',
    Subject = 'subject',
    SortByCreationDate = 'sortByCreationDate',
    AscCreationDate = 'ascCreationDate',
    Email = 'email',
    Search = 'search',
    LastName = 'lastName',
    CourseName = 'courseName',
}
