import { gql } from '@apollo/client';

export const DOWNLOAD_INTERNSHIP_REGISTRATIONS = gql`
    query ($sortByCreationDate: Boolean, $lastName: String, $email: String, $courseName: String) {
        internshipRegistrations {
            excelReport(
                filter: {
                    sortByCreationDate: $sortByCreationDate
                    lastName: $lastName
                    email: $email
                    courseName: $courseName
                }
            ) {
                data
                fileName
            }
        }
    }
`;

export default DOWNLOAD_INTERNSHIP_REGISTRATIONS;
