import { gql } from '@apollo/client';

export const UPDATE_INTERNSHIP_REGISTRATION_FORM_GQL = gql`
    mutation ($model: InternshipSettingsViewModelInput!) {
        internshipSettings {
            addOrUpdate(model: $model) {
                isOpened
                interestDirectionIsRequired
                firstNameIsRequired
                lastNameIsRequired
                emailIsRequired
                phoneIsRequired
                telegramNickNameIsRequired
                cityIsRequired
                educationIsRequired
                descriptionYourSelfIsRequired
                whatYouWantToKnowAboutInternshipIsRequired
                englishLevelIsRequired
                hoursPerWeekIsRequired
                howDidYouKnowIsRequired
                finish
                updateDate
                studyTypeIsRequired
            }
        }
    }
`;

export default UPDATE_INTERNSHIP_REGISTRATION_FORM_GQL;
