import React, { PropsWithChildren, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
    NotAuthorized,
    SignIn,
    SignOut,
    SettingsBase,
    Homepage,
    EventAnnouncement,
    AppliedEmail,
    RegistrationForm,
    DictionariesBase,
    DirectionsBase,
    InternshipTeams,
    Years,
    AddDates,
    InternshipTeam,
    Types,
    Type,
    Direction,
    Directions,
    InternshipsBase,
    Steps,
    Step,
    Feedbacks,
    Feedback,
    FAQs,
    FAQ,
    CoursesBase,
    Courses,
    Course,
    Users,
    User,
    Agreements,
    Agreement,
    SubscriptionSettings,
    SubscribeUser,
    SubscribeUsers,
} from 'containers';
import { useAuth } from 'contexts';
import { Loader } from '@kl/components-v6';
import { useTranslation } from 'react-i18next';

const Router: React.FC<PropsWithChildren> = (): JSX.Element => {
    const { isAuth } = useAuth();
    const { t } = useTranslation('common/shared');

    return (
        <Suspense fallback={<Loader centered size={'large'} tip={t('loading')} />}>
            <Routes>
                {isAuth ? (
                    <>
                        <Route path="/" element={<Navigate to={'/settings/homepage'} />} />
                        <Route path="settings" element={<SettingsBase />}>
                            <Route path="homepage" element={<Homepage />} />
                            <Route path="event-announcement" element={<EventAnnouncement />} />
                            <Route path="applied" element={<AppliedEmail />} />
                            <Route path="registration-form" element={<RegistrationForm />} />
                            <Route path="subscription-form" element={<SubscriptionSettings />} />
                            <Route path="" element={<Navigate to={'homepage'} />} />
                        </Route>
                        <Route path="dictionaries" element={<DictionariesBase />}>
                            <Route path="start-dates" element={<Years type={'start'} />} />
                            <Route path="finish-dates" element={<Years type={'finish'} />} />
                            <Route path="" element={<Navigate to={'start-dates'} />} />
                        </Route>
                        <Route path="add-start-dates/:id?" element={<AddDates type={'start'} />} />
                        <Route path="add-finish-dates/:id?" element={<AddDates type={'finish'} />} />
                        <Route path="directions" element={<DirectionsBase />}>
                            <Route path="all" element={<Directions />} />
                            <Route path="internship-teams" element={<InternshipTeams />} />
                            <Route path="types" element={<Types />} />
                            <Route path="" element={<Navigate to={'all'} />} />
                        </Route>
                        <Route path="internship-team/:id?" element={<InternshipTeam />} />
                        <Route path="type/:id?" element={<Type />} />
                        <Route path="direction/:id?" element={<Direction />} />
                        <Route path="internships" element={<InternshipsBase />}>
                            <Route path="steps" element={<Steps />} />
                            <Route path="feedbacks" element={<Feedbacks />} />
                            <Route path="faqs" element={<FAQs />} />
                            <Route path="" element={<Navigate to={'steps'} />} />
                        </Route>
                        <Route path="internship-step/:id?" element={<Step />} />
                        <Route path="internship-feedback/:id?" element={<Feedback />} />
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Route path="internship-faq/:id?" element={<FAQ />} />
                        <Route path="courses/:id" element={<Course />} />
                        <Route path="courses" element={<CoursesBase />}>
                            <Route path="all" element={<Courses />} />
                            <Route path="users" element={<Users />} />
                            <Route path="agreements" element={<Agreements />} />
                            <Route path="" element={<Navigate to={'all'} />} />
                        </Route>
                        <Route path="course" element={<Course />} />
                        <Route path="user/:id" element={<User />} />
                        <Route path="agreement/:id" element={<Agreement />} />
                        <Route path="internship-feedback/:id?" element={<Feedback />} />
                        <Route path="subscribed-user/:id" element={<SubscribeUser />} />
                        <Route path="subscribed-users" element={<SubscribeUsers />} />
                    </>
                ) : (
                    <Route path="*" element={<NotAuthorized />} />
                )}
                <Route path="/signin" element={<SignIn />} />
                <Route path="/logout" element={<SignOut />} />
            </Routes>
        </Suspense>
    );
};

export default Router;
