import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Heading, Loader, Select } from '@kl/components-v6';
import UPDATE_INTERNSHIP_REGISTRATION_FORM_GQL from 'api/mutations/updateInternshipRegistrationForm';
import GET_COURSES_GQL from 'api/queries/getCourses';
import GET_INTERNSHIP_REGISTRATION_FORM_GQL from 'api/queries/getInternshipRegistrationForm';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { PaginationOutput } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from 'styled/global-style';
import { RegistrationForm as RegistrationFormModel, StepikCourse } from 'types';

const RegistrationForm: FC = () => {
    const [registrationForm, setRegistrationForm] = useState<RegistrationFormModel | null>(null);
    const [stepikCourses, setStepikCourses] = useState<PaginationOutput<StepikCourse> | null>(null);
    const { loading: registrationFormLoading } = useQuery(GET_INTERNSHIP_REGISTRATION_FORM_GQL, {
        onCompleted: (response) => {
            setRegistrationForm({ ...response.internshipSettings.byId, emailIsRequired: true });
        },
    });
    const { loading: coursesLoading } = useQuery(GET_COURSES_GQL, {
        variables: { page: 0, size: 300 },
        onCompleted: (response) => {
            setStepikCourses(response.courses.get);
        },
    });
    const [registrationFormMutation, { loading: mutationLoading }] = useMutation(
        UPDATE_INTERNSHIP_REGISTRATION_FORM_GQL
    );
    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();

    const methods = useForm<RegistrationFormModel>();
    const { control } = methods;

    const onSubmit = async (model: FieldValues) => {
        try {
            model.emailIsRequired = true;
            const response = await registrationFormMutation({
                variables: {
                    model: { ...model, updateDate: new Date().toISOString(), interestDirectionIsRequired: true },
                },
            });
            const {
                data: {
                    internshipSettings: { addOrUpdate },
                },
            } = response;
            setRegistrationForm(addOrUpdate);
            setToaster({
                type: 'success',
                message: t('registration-form-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return registrationFormLoading || coursesLoading || !stepikCourses || !registrationForm ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <Heading type={'H2'}>{t('registration-form')}</Heading>
            <FormProvider {...methods}>
                <FormBuilder<RegistrationFormModel>
                    data={registrationForm}
                    submit={onSubmit}
                    formKey={FormBuilderKeys.RegistrationForm}
                    loading={mutationLoading}
                >
                    <>
                        <FormRow>
                            <span>{t('courseId', { ns: 'common/shared' })}</span>
                            <Controller
                                name={'courseId'}
                                control={control}
                                defaultValue={registrationForm?.courseId || undefined}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        showSearch
                                        onChange={onChange}
                                        defaultValue={registrationForm?.courseId || undefined}
                                        options={stepikCourses?.items?.map((option: StepikCourse) => ({
                                            label: option.name,
                                            value: option.id,
                                        }))}
                                    />
                                )}
                            />
                        </FormRow>
                    </>
                </FormBuilder>
            </FormProvider>
        </>
    );
};

export default RegistrationForm;
