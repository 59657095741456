import { gql } from '@apollo/client';

export const GET_COURSES_GQL = gql`
    query ($page: Int!, $size: Int!) {
        courses {
            get(filter: { page: $page, size: $size }) {
                count
                items {
                    id
                    creationDate
                    name
                }
            }
        }
    }
`;

export default GET_COURSES_GQL;
