import { AppliedEmail } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';
import { fileSize } from 'containers/form-builder/rules';
import { GetFile } from 'kl-b2c-ui-kit';
import { ACCEPTED_IMAGE_FORMATS, TEN_MB_IN_BYTES } from 'consts';

export const rejectedEmailRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<keyof AppliedEmail, { type: ControlRendererType; rules?: RegisterOptions }> => ({
    creationDate: {
        type: ControlRendererType.DateTime,
    },
    text: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            maxLength: {
                value: 10000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 10000,
                }),
            },
        },
    },
    image: {
        type: ControlRendererType.UploadImage,
        rules: {
            required: {
                value: true,
                message: t('required-image', {
                    ns: 'common/errors',
                }),
            },
            validate: (file: File | GetFile) => {
                return fileSize(file, TEN_MB_IN_BYTES, ACCEPTED_IMAGE_FORMATS, t);
            },
        },
    },
    id: {
        type: ControlRendererType.Hidden,
    },
});
