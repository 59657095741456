import { CityType } from 'enums/city-type';
import { EnglishLevel } from 'enums/english-level';
import { HoursPerWeek } from 'enums/hours-per-week';

export const ENGLISH_LEVEL_MAPPER: Record<EnglishLevel, string> = {
    0: 'Не владею',
    1: 'A1 (Elementary) — начальный',
    2: 'A2 (Pre-Intermediate) — базовый; ',
    3: 'B1 (Intermediate) — средний; ',
    4: 'B2 (Upper Intermediate) — выше среднего; ',
    5: 'C1 (Advanced) — высокий;',
    6: 'C2 (Proficiency) — носитель языка.',
};

export const HOURS_PER_WEEK_MAPPER: Record<HoursPerWeek, string> = {
    1: '20 часов в неделю',
    2: '25 часов в неделю',
    3: '30 часов в неделю',
    4: '35 часов в неделю',
};

export const CITY_TYPE_MAPPER: Record<CityType, string> = {
    1: 'Москва и область',
    2: 'СПБ',
    3: 'Другое',
};
