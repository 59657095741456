import { Direction } from 'types';
import { ControlRendererType } from 'enums';
import { RegisterOptions } from 'react-hook-form';

export const directionRendererTypes = (
    t: (key: string, options?: Record<string, number | string>) => string
): Record<
    keyof Omit<Direction, 'id' | 'creationDate' | 'typeId' | 'internshipTeamIds'>,
    { type: ControlRendererType; rules?: RegisterOptions }
> => ({
    link: {
        type: ControlRendererType.TextBox,
        rules: {
            maxLength: {
                value: 4000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 4000,
                }),
            },
        },
    },
    vacancyId: {
        type: ControlRendererType.TextBox,
        rules: {
            maxLength: {
                value: 100,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 100,
                }),
            },
        },
    },
    name: {
        type: ControlRendererType.TextBox,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 255,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 255,
                }),
            },
        },
    },
    type: {
        type: ControlRendererType.Hidden,
    },
    internshipTeams: {
        type: ControlRendererType.Hidden,
    },
    number: {
        type: ControlRendererType.Number,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            min: {
                value: 1,
                message: t('min-val', {
                    ns: 'common/errors',
                    value: 1,
                }),
            },
        },
    },
    description: {
        type: ControlRendererType.Wysiwyg,
        rules: {
            required: {
                value: true,
                message: t('required-field', {
                    ns: 'common/errors',
                }),
            },
            maxLength: {
                value: 2000,
                message: t('max-length', {
                    ns: 'common/errors',
                    length: 2000,
                }),
            },
        },
    },
    typeName: {
        type: ControlRendererType.Hidden,
    },
    visible: {
        type: ControlRendererType.CheckBox,
    },
});
