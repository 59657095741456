import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Loader, Select } from '@kl/components-v6';
import UPDATE_INTERNSHIP_REGISTRATION_GQL from 'api/mutations/updateUser';
import GET_DIRECTIONS_GQL from 'api/queries/getDirections';
import GET_INTERNSHIP_REGISTRATION_GQL from 'api/queries/getInternshipRegistration';
import { FormBuilder } from 'containers';
import { ErrorMessage } from 'containers/form-builder/styled';
import { useToaster } from 'contexts/index';
import { FormBuilderKeys } from 'enums';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormRow } from 'styled/global-style';
import { Direction, User as UserModel } from 'types';

type UserModelUpdate = { directionsId: string[] } & UserModel;

export const INITIAL_DATA: UserModelUpdate = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    englishLevel: null,
    hoursPerWeek: null,
    howDidYouKnow: null,
    lastName: '',
    email: '',
    phone: '',
    telegramNickName: '',
    city: '',
    university: '',
    faculty: '',
    descriptionYourSelf: '',
    directionsNames: [],
    directionsId: [],
    directions: [],
    education: '',
    startEducationStr: '',
    finishEducationStr: '',
    registrationCode: '',
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
    whatYouWantToKnowAboutInternship: '',
    agreementId: '',
};

const User: FC = () => {
    const [user, setUser] = useState<UserModelUpdate>(INITIAL_DATA);
    const [directions, setDirections] = useState<Direction[] | null>(null);
    const { t } = useTranslation(['pages/courses', 'common/shared']);
    const navigate = useNavigate();
    const { setToaster } = useToaster();

    const [getUserQuery, { loading: userLoading }] = useLazyQuery(GET_INTERNSHIP_REGISTRATION_GQL, {
        onCompleted: (response) => {
            setUser(response.internshipRegistrations.byId);
        },
    });

    const [getDirectionsQuery, { loading: directionsLoading }] = useLazyQuery(GET_DIRECTIONS_GQL, {
        onCompleted: (response) => {
            setDirections(response.directions.get.items);
        },
    });

    const [updateUserMutation, { loading: updateMutationLoading }] = useMutation(UPDATE_INTERNSHIP_REGISTRATION_GQL, {
        refetchQueries: [GET_INTERNSHIP_REGISTRATION_GQL],
    });

    const { id } = useParams();
    const methods = useForm<UserModelUpdate>();
    const {
        control,
        formState: { errors },
        watch,
    } = methods;

    useEffect(() => {
        if (id) {
            getUserQuery({ variables: { id } });
            getDirectionsQuery({ variables: { page: 0, size: 300 } });
        }
    }, []);

    const onSubmit = async (model: FieldValues) => {
        try {
            const { firstName, lastName, directionsId } = model;
            await updateUserMutation({
                variables: {
                    model: {
                        firstName,
                        lastName,
                        directionsId,
                        id,
                    },
                },
            });
            setToaster({
                type: 'success',
                message: t('user-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return !userLoading && !directionsLoading ? (
        <FormProvider {...methods}>
            <FormBuilder<UserModelUpdate>
                data={user}
                submit={onSubmit}
                formKey={FormBuilderKeys.User}
                loading={updateMutationLoading}
                cancel={() => navigate('/courses/users')}
            >
                <FormRow>
                    <span>{t('directionsId', { ns: 'common/shared' })}*</span>
                    <Controller
                        name={'directionsId'}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('required-field', {
                                    ns: 'common/errors',
                                }),
                            },
                        }}
                        defaultValue={user.directions.map((direction: Direction) => direction.id)}
                        render={({ field: { onChange } }) => (
                            <Select
                                showSearch
                                mode={'multiple'}
                                onChange={onChange}
                                defaultValue={user.directions.map((direction: Direction) => direction.id)}
                                options={directions?.map((option: Direction) => ({
                                    label: option.name,
                                    value: option.id,
                                    disabled:
                                        watch('directionsId').length >= 3 && !watch('directionsId').includes(option.id),
                                }))}
                            />
                        )}
                    />
                    {errors['directionsId'] && <ErrorMessage>{errors['directionsId'].message}</ErrorMessage>}
                </FormRow>
            </FormBuilder>
        </FormProvider>
    ) : (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    );
};

export default User;
