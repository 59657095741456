import { CellRenderType } from 'enums';
import { Agreement, Agreements, Course, Users, User } from 'types';

export const usersInitialColumns: Omit<Users, 'directions'> = {
    id: '',
    creationDate: new Date(),
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    telegramNickName: '',
    cityId: null,
    city: '',
    courseId: '',
    courseName: '',
    education: '',
    university: '',
    faculty: '',
    descriptionYourSelf: '',
    directionsNames: [],
    startEducationStr: '',
    finishEducationStr: '',
    registrationCode: '',
    agreementId: '',
    whatYouWantToKnowAboutInternship: '',
    utmCampaign: '',
    utmContent: '',
    utmMedium: '',
    utmSource: '',
    utmTerm: '',
    englishLevel: null,
    hoursPerWeek: null,
    howDidYouKnow: null,
};

export const usersCellRenderMapper: Record<keyof Omit<User, 'directions'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    firstName: CellRenderType.TEXT,
    lastName: CellRenderType.TEXT,
    cityId: CellRenderType.CITY_TYPE,
    courseId: CellRenderType.TEXT,
    courseName: CellRenderType.TEXT,
    city: CellRenderType.TEXT,
    education: CellRenderType.TEXT,
    descriptionYourSelf: CellRenderType.TEXT,
    email: CellRenderType.TEXT,
    faculty: CellRenderType.TEXT,
    finishEducationStr: CellRenderType.TEXT,
    startEducationStr: CellRenderType.TEXT,
    phone: CellRenderType.TEXT,
    registrationCode: CellRenderType.TEXT,
    telegramNickName: CellRenderType.TEXT,
    university: CellRenderType.TEXT,
    englishLevel: CellRenderType.ENGLISH_LEVEL,
    hoursPerWeek: CellRenderType.HOURS_PER_WEEK,
    howDidYouKnow: CellRenderType.TEXT,
    utmCampaign: CellRenderType.TEXT,
    utmContent: CellRenderType.TEXT,
    utmMedium: CellRenderType.TEXT,
    utmSource: CellRenderType.TEXT,
    utmTerm: CellRenderType.TEXT,
    whatYouWantToKnowAboutInternship: CellRenderType.TEXT,
    directionsNames: CellRenderType.ARRAY_OF_VALUES,
    agreementId: CellRenderType.AGREEMENT_ID,
};

export const coursesInitialColumns: Omit<Course, 'report'> = {
    id: '',
    creationDate: new Date(),
    name: '',
};

export const coursesCellRenderMapper: Record<keyof Omit<Course, 'report'>, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
};

export const agreementsInitialColumns: Agreements = {
    id: '',
    creationDate: new Date(),
    email: '',
    touCheckBoxIsSelected: false,
    privacyPolicyIsSelected: false,
    marketingIsSelected: false,
    marketingCheckBoxText: '',
    touCheckBoxText: '',
    privacyPolicyCheckBoxText: '',
    privacyPolicyText: '',
};

export const agreementsCellRenderMapper: Record<keyof Agreement, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    email: CellRenderType.TEXT,
    touCheckBoxIsSelected: CellRenderType.BOOLEAN,
    privacyPolicyIsSelected: CellRenderType.BOOLEAN,
    marketingIsSelected: CellRenderType.BOOLEAN,
    marketingCheckBoxText: CellRenderType.TEXT,
    touCheckBoxText: CellRenderType.TEXT,
    touText: CellRenderType.TEXT,
    privacyPolicyCheckBoxText: CellRenderType.TEXT,
    privacyPolicyText: CellRenderType.TEXT,
    chapter: CellRenderType.TEXT,
};
