import { gql } from '@apollo/client';

export const GET_INTERNSHIP_REGISTRATIONS_GQL = gql`
    query (
        $page: Int!
        $size: Int!
        $sortByCreationDate: Boolean
        $lastName: String
        $email: String
        $courseName: String
    ) {
        internshipRegistrations {
            get(
                filter: {
                    page: $page
                    size: $size
                    sortByCreationDate: $sortByCreationDate
                    lastName: $lastName
                    email: $email
                    courseName: $courseName
                }
            ) {
                count
                items {
                    id
                    agreementId
                    creationDate
                    firstName
                    lastName
                    email
                    phone
                    telegramNickName
                    courseId
                    courseName
                    cityId
                    city
                    education
                    university
                    faculty
                    startEducationStr
                    finishEducationStr
                    descriptionYourSelf
                    registrationCode
                    directionsNames
                    whatYouWantToKnowAboutInternship
                    utmTerm
                    utmMedium
                    utmCampaign
                    utmSource
                    utmContent
                    englishLevel
                    hoursPerWeek
                    howDidYouKnow
                }
            }
        }
    }
`;

export default GET_INTERNSHIP_REGISTRATIONS_GQL;
