import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Heading, Loader } from '@kl/components-v6';
import UPDATE_LETTER_TPL_GQL from 'api/mutations/updateEmailTpl';
import GET_APPLIED_EMAIL_TPL_GQL from 'api/queries/getAppliedEmailTpl';
import { FormBuilder } from 'containers';
import { useToaster } from 'contexts';
import { FormBuilderKeys } from 'enums';
import { GetFile } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppliedEmail as AppliedEmailModel } from 'types';
import { getByteArray } from 'utils/get-byte-array';

const AppliedEmail: FC = () => {
    const [appliedEmail, setAppliedEmail] = useState<AppliedEmailModel | null>(null);
    const { t } = useTranslation(['pages/config', 'common/shared']);
    const { setToaster } = useToaster();
    const { loading: emailLoading } = useQuery(GET_APPLIED_EMAIL_TPL_GQL, {
        onCompleted: (response) => {
            setAppliedEmail(response.internshipEmailTemplate.appliedTemplate);
        },
    });
    const [emailMutation, { loading: mutationLoading }] = useMutation(UPDATE_LETTER_TPL_GQL);

    const methods = useForm<AppliedEmailModel>();
    const { control } = methods;

    const onSubmit = async (model: FieldValues) => {
        try {
            const bytes = await getByteArray(model.image as File);
            const image = model.image as File;
            await emailMutation({
                variables: {
                    model: {
                        ...model,
                        id: appliedEmail?.id,
                        image: (model.image as GetFile).id
                            ? null
                            : { contentType: image.type, data: bytes, fileName: image.name },
                    },
                },
            });
            setToaster({
                type: 'success',
                message: t('applied-email-update-success'),
            });
        } catch (e: unknown) {
            if (e instanceof ApolloError) {
                setToaster({
                    type: 'error',
                    message: e.message ? e.message : t('something-wrong', { ns: 'common/errors' }),
                });
            }
        }
    };

    return emailLoading || !appliedEmail ? (
        <Loader centered size={'large'} tip={t('loading', { ns: 'common/shared' })} />
    ) : (
        <>
            <>
                <Heading type={'H2'}>{t('applied-email')}</Heading>
                <FormProvider {...methods}>
                    <FormBuilder<AppliedEmailModel>
                        data={appliedEmail}
                        submit={onSubmit}
                        formKey={FormBuilderKeys.AppliedEmail}
                        loading={mutationLoading}
                    />
                </FormProvider>
            </>
        </>
    );
};

export default AppliedEmail;
