import { gql } from '@apollo/client';

export const GET_INTERNSHIP_REGISTRATION_GQL = gql`
    query ($id: UUID!) {
        internshipRegistrations {
            byId(filter: { id: $id }) {
                id
                creationDate
                agreementId
                firstName
                lastName
                email
                phone
                telegramNickName
                courseId
                courseName
                cityId
                city
                education
                university
                faculty
                startEducationStr
                finishEducationStr
                descriptionYourSelf
                whatYouWantToKnowAboutInternship
                registrationCode
                directions {
                    id
                    name
                }
                directionsNames
                englishLevel
                hoursPerWeek
                howDidYouKnow
                utmTerm
                utmMedium
                utmCampaign
                utmSource
                utmContent
            }
        }
    }
`;

export default GET_INTERNSHIP_REGISTRATION_GQL;
