import React from 'react';
import { CellRenderType } from 'enums';
import { Direction, Directions, InternshipTeam, Type } from 'types';

export const initialColumns: InternshipTeam = {
    id: '',
    name: '',
    creationDate: new Date(),
    additionalRequirement: '',
    candidateRequirement: '',
    content: '',
    description: '',
};

export const cellRenderMapper: Record<keyof InternshipTeam, CellRenderType> = {
    id: CellRenderType.TEXT,
    name: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    additionalRequirement: CellRenderType.TEXT,
    content: CellRenderType.TEXT,
    candidateRequirement: CellRenderType.TEXT,
    description: CellRenderType.TEXT,
};

export const typeInitialColumns: Type = {
    id: '',
    creationDate: new Date(),
    name: '',
};

export const typeCellRenderMapper: Record<keyof Type, CellRenderType> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
};

export const directionsInitialColumns: Omit<Directions, 'internshipTeams' | 'type' | 'typeId' | 'internshipTeamIds'> = {
    id: '',
    creationDate: new Date(),
    name: '',
    number: 1,
    typeName: '',
    vacancyId: '',
    visible: false,
};

export const directionsCellRenderMapper: Record<
    keyof Omit<Direction, 'internshipTeams' | 'type' | 'typeId' | 'internshipTeamIds'>,
    CellRenderType
> = {
    id: CellRenderType.TEXT,
    creationDate: CellRenderType.DATETIME,
    name: CellRenderType.TEXT,
    description: CellRenderType.TEXT,
    link: CellRenderType.TEXT,
    number: CellRenderType.TEXT,
    typeName: CellRenderType.TEXT,
    vacancyId: CellRenderType.TEXT,
    visible: CellRenderType.BOOLEAN,
};
