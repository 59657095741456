export const getByteArray = (file: File): Promise<string | null> => {
    return new Promise((resolve) => {
        //@ts-ignore
        if (file.id) {
            resolve(null);
        }
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
            resolve(base64String);
        };
        reader.readAsDataURL(file);
    });
};
