import { gql } from '@apollo/client';

export const GET_DIRECTIONS_GQL = gql`
    query ($page: Int!, $size: Int!) {
        directions {
            get(filter: { page: $page, size: $size, sortByNumber: true }) {
                count
                items {
                    id
                    name
                    number
                    visible
                    typeName
                    creationDate
                    vacancyId
                }
            }
        }
    }
`;

export default GET_DIRECTIONS_GQL;
